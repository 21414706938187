import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/var/www/phimheofuk.com/node_modules/@nextui-org/image/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/next/font/google/target.css?{\"path\":\"src/app/(home)/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/components/Adsver/CatfishBottom.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/components/Adsver/Popup_desktop.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/components/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/components/Header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/components/Navbar.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/src/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/providers/AccountProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/providers/NextUiProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/providers/ReactQueryProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/providers/SnackbarProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/providers/WatchMovieMode.jsx");
