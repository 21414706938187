'use client'
import React, { useReducer,createContext,useMemo,useContext, useEffect } from "react";
import { getAuth } from "../server/api/auth/getAuth";

export const AccountContext = createContext({
    account: {},
    setAccount: (params) => {},
});

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_ACCOUNT":
            return {...state, ...action.payload.user}
        case "DELETE_ACCOUNT":
            return {}
        default: {
            return state;
      }
    }
};

export const useCurrentAccountContext = () => useContext(AccountContext);

export default function AccountProvider({ children }) {
    const [account, setAccount] = useReducer(reducer,{});
    const contextValue = useMemo(
        () => ({
            account,
            setAccount,
        }),
        [account, setAccount]
    );
    useEffect( () => {
        getAuth().then((data) => {
            if(Object.keys(data).length > 0 && !data.hasOwnProperty('error')){
                setAccount({
                    type: "SET_ACCOUNT",
                    payload: data,
                });
            }
        })
    }, []);
    return (
        <AccountContext.Provider value={contextValue}>
            {children}
        </AccountContext.Provider>
    )
}