"use client"
// @ts-ignore
import React, { useEffect, useState } from "react";
import Image from 'next/image';
import SearchForm from "./SearchBox/SearchForm";
import { FiSearch } from "react-icons/fi";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle } from "@nextui-org/navbar";
import { Button } from "@nextui-org/button";
import { useDisclosure } from "@nextui-org/modal";
import MobileSearchForm from "./Modal/MobileSearchForm";
import { FaChevronDown } from "react-icons/fa6";
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/dropdown";
import Link from "next/link";
import { ScrollShadow } from "@nextui-org/scroll-shadow";

// @ts-ignore
export default function Header({types,categories}) {
    // @ts-ignore
    const { isDownLg } = useBreakpoint('lg');
    const [keyPosi, setKeyPosi] = React.useState("wTopbar");
    const topVarMenu = {
        wTopbar:"top-[104px]",
        nTopbar:"top-[64px]"
    }
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const onScrollChange = (position) => {
        if(position >= 100){
            setKeyPosi('nTopbar')
        }else{
            setKeyPosi('wTopbar')
        }
    }
    const { 
        isOpen: isOpenMobileSearchFormModal, 
        onOpen: onOpenMobileSearchFormModal, 
        onOpenChange: onOpenMobileSearchFormChange 
    } = useDisclosure();
    


    return (
        <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} onScrollPositionChange={onScrollChange} isBordered={isDownLg ? true : false} shouldHideOnScroll maxWidth="2xl" className="bg-background z-50">
            <NavbarBrand className="max-w-[230px] justify-center h-full">
                <Link href={`/`} className="h-full py-2">
                    <Image width={230} height={64} className="h-full object-contain" src="/logo.webp" alt={"Ecommflex"}/>
                </Link>
            </NavbarBrand>
            <NavbarContent justify="center" className="hidden md:flex lg:min-w-[668px]">
                <NavbarItem className="w-full">
                    <SearchForm onClose={undefined} categories={categories}/>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end" className="flex max-w-[350px]">
                <NavbarItem className="md:hidden">
                    <Button
                        onClick={onOpenMobileSearchFormModal}
                        isIconOnly
                        className="bg-foreground/10 p-2"
                        radius="full"
                        variant="light"
                    >
                        <FiSearch size={20}/>
                    </Button>
                </NavbarItem>
            </NavbarContent>
            
            <NavbarMenuToggle
                aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                className="md:hidden bg-foreground/10 h-10 rounded-full w-10"
            />
            <NavbarMenu className={`${topVarMenu[keyPosi]} py-8`}>
                {
                    categories.map((category) => 
                    <NavbarMenuItem key={category.id}>
                        <Button
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            prefetch={false}
                            href={`/danh-muc/${category.slug}`} 
                            as={Link}
                            className="p-0 bg-transparent data-[hover=true]:bg-transparent justify-start"
                            variant="light"
                            size="lg"
                        >
                            {category.name}
                        </Button>
                    </NavbarMenuItem>
                    )
                }
                <NavbarMenuItem>
                    <Dropdown>
                        <NavbarItem>
                            <DropdownTrigger>
                                <Button
                                    disableRipple
                                    className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                                    endContent={<FaChevronDown/>}
                                    variant="light"
                                    size="lg"
                                >
                                Thể Loại
                                </Button>
                            </DropdownTrigger>
                        </NavbarItem>
                        <DropdownMenu
                            aria-label="Thể loại"
                            className="w-[350px]"
                        >
                            <DropdownItem className="text-foreground data-[hover=true]:bg-transparent data-[hover=true]:text-foreground" >
                                <ScrollShadow className="h-[200px]">
                                    <div className="grid grid-cols-3 gap-2">
                                        {
                                            types.map((item) => 
                                                <Link onClick={() => setIsMenuOpen(!isMenuOpen)} key={item.id} href={`/the-loai/${item.slug}`} prefetch={false}>{item.name}</Link>
                                            )
                                        }
                                    </div>
                                </ScrollShadow>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavbarMenuItem>
                <NavbarMenuItem>
                    <Button
                        prefetch={false}
                        href={`/dien-vien`} 
                        as={Link}
                        className="p-0 bg-transparent data-[hover=true]:bg-transparent justify-start"
                        variant="light"
                        size="lg"
                    >
                        Diễn Viên
                    </Button>
            </NavbarMenuItem>
            </NavbarMenu>
            <MobileSearchForm categories={categories} isOpen={isOpenMobileSearchFormModal} onOpenChange={onOpenMobileSearchFormChange}/>
        </Navbar>
    );
}
