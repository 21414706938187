export const Config = {
    languages : [
        { id: 1, name: 'English', code: 'en', flag: "https://flagcdn.com/us.svg" },
        { id: 2, name: 'Việt Nam', code: 'vi', flag: "https://flagcdn.com/vn.svg" }
    ],
    phone: "cliphot69com",
};

export const moviePerPage = {
    movie_pre_generate:10,
    search_take:20,
    banner_take:10,
    hot_take:15,
    single_take:15,
    populary_take:10,
    ramdom_take:10,
    most_search_key:40,
    movie_take_by_type:24,
    movie_take_by_category:24,
    movie_take_by_category_home:15,
    movie_take_by_country:30,
    movie_take_by_year:30,
    movie_take_by_search:30,
    movie_take_by_cast:24,
    cast_take_list:40
}

