"use client"
import React from 'react'
import Link from 'next/link';

export default function Footer({categories}) {
    const infomations = [
        {
            id:1,
            name:"Liên Hệ Chúng Tôi",
            slug:'trang/lien-he-chung-toi',
        },
    ];
    return (
        <div className="bg-background w-full">
            <div className="max-w-[1536px] m-auto py-8 h-full">
                <div className="flex flex-wrap justify-center gap-8">
                    <div className="xl:max-w-[calc(40%_-_96px)] lg:max-w-[calc(40%_-_96px)] md:max-w-[calc(50%_-_16px)] max-w-[calc(70%_-_32px)] w-full">
                        <div className='flex flex-col'>
                            <p className="text-small max-w-[400px] my-4">
                            Trang web có chứa nội dung (18+) Hãy đảm bảo rằng bạn đã đủ 18+ tuổi khi xem Phim sex tại phimheofuk.com. Chúng tôi sẽ không chịu bất cứ tránh nhiệm nào nếu bạn nhỏ hơn 18 tuổi mà vẫn xem phim người lớn.
                            Tất cả nội dung phim đều không có thật, người xem tuyệt đối không bắt chước hành động trong phim, tránh vi phạm pháp luật.
                            </p>
                        </div>
                    </div>
                    <div className="xl:max-w-[20%] lg:max-w-[20%] md:max-w-[calc(50%_-_16px)] max-w-[25%] w-full">
                        <h1 className="text-lg font-semibold my-4">Clip Hay</h1>
                        <ul className="list-none">
                            {categories.map((item) => (
                                <li className='my-2' key={item.id}><Link prefetch={false} className='text-foreground' href={`/danh-muc/${item.slug}`}>{item.name}</Link></li>
                            ))}
                        </ul>
                    </div>
                    <div className="xl:max-w-[20%] lg:max-w-[20%] md:max-w-[calc(50%_-_16px)] max-w-[calc(45%_-_16px)] w-full">
                        <h1 className="text-lg font-semibold my-4">Liên Kết</h1>
                        <ul className="list-none">
                            <li className='my-2'><Link prefetch={false} className='text-foreground' href="https://cliphott69.com">Cliphott69</Link></li>
                            <li className='my-2'><Link prefetch={false} className='text-foreground' href="https://heovl.blog">heovl</Link></li>
                            <li className='my-2'><Link prefetch={false} className='text-foreground' href="https://phimhottt.com">PhimHot</Link></li>
                        </ul>
                    </div>
                    <div className="xl:max-w-[20%] lg:max-w-[20%] md:max-w-[calc(50%_-_16px)] max-w-[calc(55%_-_16px)] w-full">
                        <h1 className="text-lg font-semibold my-4">Thông Tin</h1>
                        <ul className="list-none">
                            {infomations.map((item) => (
                                <li className='my-2' key={item.id}><Link prefetch={false} className='text-foreground' href={`/${item.slug}`}>{item.name}</Link></li>
                            ))}
                        </ul>
                        {/* <SocialButtonList/> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
