"use client"
import React from 'react'
import MovieMediumCard from '../Card/MovieMediumCard'

export default function MovieListSearch({movies,type,onClose,fromSearch}) {
    return (
        <div className={`grid grid-flow-row grid-cols-2 gap-4 my-4 md:grid-cols-3 lg:grid-cols-4 ${fromSearch ? 'xl:grid-cols-6' : ''}`}>
            {
                movies && movies.map((movie) => {
                    if(type){
                        movie = movie.movie;
                    }
                    return (<MovieMediumCard onClose={onClose} key={movie.id} movie={movie} />)
                })
            }
        </div>
    )
}
