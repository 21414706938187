// @ts-nocheck
import React, { useEffect } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import { Button } from '@nextui-org/button';

export default function DropDownOption({setSearchWithCat,categories}) {
    const [catInSearch, setCatInSearch] = React.useState({});
    const [categoriesOption, setCategoriesOption] = React.useState([...categories]);
    useEffect(()=>{
        if(categoriesOption[0].id !== 0){
            categoriesOption.unshift({
                id:0,
                name:"Tất Cả"
            })
        }
    },[categories])
    const setObjectCatForSearch = (key) => {
        const value = categoriesOption.filter((item) => item.id == key)[0];
        const valueSet = {
            name : value.name,
            key: value.id
        };
        setCatInSearch(valueSet);
        setSearchWithCat(value.id);
    }
    return (
        <Dropdown>
            <DropdownTrigger>
                <Button 
                    color="default"
                    variant="solid"
                    className="capitalize rounded-r-full px-8"
                >
                    { Object.keys(catInSearch).length > 0 ? catInSearch.name : "Tất Cả" }
                </Button>
            </DropdownTrigger>
            <DropdownMenu 
                onAction={(key) => setObjectCatForSearch(key)}
                aria-label="Dropdown Variants"
                color="default" 
                variant="solid"
                className=''
            >
                {categoriesOption.map((category) => {
                    const CatTitle = category.name;
                    return (
                        <DropdownItem key={category.id}>{CatTitle}</DropdownItem>
                    )
                })}
            </DropdownMenu>
        </Dropdown>
    )
}