// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { FiSearch } from "react-icons/fi";
import { Input } from '@nextui-org/input';

export default function InputSearch({setIsHiddenResult,setSearchValue}) {
    const [value, setValue] = React.useState("");
    const [syncTimer, setSyncTimer] = useState(0);

    const actionInput = () => {
        clearTimeout(syncTimer);
        const newTimer = setTimeout(() => {
            setSearchValue(value)
            setSyncTimer(0);
        }, 1000);
        setSyncTimer(newTimer);
    };

    return (
        <Input
            onFocus={() => setIsHiddenResult(true)}
            onKeyUp={actionInput}
            classNames={{
                base: "max-w-full h-10",
                mainWrapper: "h-full",
                input: "focus:text-base text-small",
                inputWrapper: "h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20 rounded-l-full pl-6",
            }}
            placeholder="Nhập Tên Phim (Có Hoặc Không Dấu)"
            size="sm"
            isClearable
            value={value}
            onValueChange={setValue}
            startContent={<FiSearch size={18} />}
            onClear={() => setValue("")}
            type="text"
        />
    )
}
