'use client'
import React, { useReducer,createContext,useMemo,useContext, useEffect } from "react";

export const WatchMovieModeContext = createContext({
    settings: {},
    setSettings: (obj) => {},
});

const reducer = (state, action) => {
    switch (action.type) {
      case "FULL_SCREEN_WEB":
        return {
            'FULL_SCREEN_WEB':action.state
        };
      default: {
        return state;
      }
    }
};

export const useWatchMovieModeContext = () => useContext(WatchMovieModeContext);

export default function WatchMovieModeProvider({ children }) {
    const [settings, setSettings] = useReducer(reducer,{});
    const contextValue = useMemo(
        () => ({
            settings,
            setSettings,
        }),
        [settings, setSettings]
    );
    return (
        <WatchMovieModeContext.Provider value={contextValue}>
            {children}
        </WatchMovieModeContext.Provider>
    )
}