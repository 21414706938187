'use client'
import Link from 'next/link';
import Image from 'next/image';
import React, { useState } from 'react';
import { PiCaretDoubleDownBold, PiCaretDoubleUpBold } from "react-icons/pi";
import { Button } from '@nextui-org/button';

export default function Catfish({catfishData}) {
    const [catFishBottom, setCatFishBottom] = useState("show");
    const positions = {
        show:"bottom-0",
        hide:`bottom-[-${90 * parseInt(catfishData.length)}px]`
    }
    const toggleCatfishBottom = () => {
        if(catFishBottom === 'show'){
            setCatFishBottom('hide');
        }else{
            setCatFishBottom('show');
        }
    }
    return (
        catfishData.length > 0 && 
        (
            <div className={`w-full fixed ${positions[catFishBottom]} z-10 justify-center flex`}>
                <div className="max-w-fit relative">
                <Button className='absolute top-[-32px] right-0' isIconOnly size='sm' onClick={toggleCatfishBottom}>
                    {
                        catFishBottom === 'show' ?
                        <PiCaretDoubleDownBold/> :
                        <PiCaretDoubleUpBold/>
                    }
                </Button>  
                {
                    catfishData.map((item) => <Link className='flex justify-center' key={item.id} href={item.url} prefetch={false} target='_blank'><Image style={{ width: '100%', height: 'auto', maxHeight: '90px', maxWidth: 'fit-content' }} width={0} height={0} objectFit='contain' src={item.image} alt={'Quảng Cáo'} /></Link>)
                }
                </div>
            </div>
        )
    )
}
